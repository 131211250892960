<template>
  <div>
    <spoiled-banner
        class="mb-5"
    >
      <spoiled-area-filter v-model="areas" class="mb-3"/>
      <spoiled-search v-model="search"/>
    </spoiled-banner>

    <v-timeline
        dense
        align-top
        v-if="!!playthroughFiltered.length"
    >
      <v-timeline-item
          v-for="sphere in playthroughFiltered"
          :key="sphere.name"
          :color="sphere.color"
          :icon="sphere.index.toString()"
          large
          fill-dot
          class="mb-5"
      >
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3" v-for="step in sphere.steps" :key="step.id">
            <spoiled-location :value="step"/>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>

    <v-progress-linear :value="triforceProgress" color="amber" height="15" fixed bottom class="mb-16 progress"
                       v-if="hasTriforce">
      <v-layout d-flex justify-center align-center>
        <v-icon small>mdi-triforce</v-icon>
        <div class="caption ml-1">{{ triforceCurrent }} / {{ triforceTotal }}</div>
      </v-layout>
    </v-progress-linear>
    <v-progress-linear :value="progress" :color="progressColor" height="15" fixed bottom class="mb-12 progress">
      <div class="caption">{{ progress }}%</div>
    </v-progress-linear>
  </div>
</template>

<script>
import SpoiledLocation from "@/components/base/SpoiledLocation";
import SpoiledAreaFilter from "@/components/base/SpoiledAreaFilter";
import SpoiledSearch from "@/components/base/SpoiledSearch.vue";
import SpoiledBanner from "@/components/base/SpoiledBanner.vue";

export default {
  name: "SpoiledPlaythrough",
  components: {SpoiledBanner, SpoiledSearch, SpoiledAreaFilter, SpoiledLocation},
  data: function () {
    return {
      areas: [],
      search: null,
    };
  },
  computed: {
    locations() {
      return this.$store.getters['Log/locations'];
    },
    playthrough() {
      let index = 0;

      return this.$store.getters['Log/playthrough'].map(sphere => {
        let newSphere = {
          index: index++,
          ...sphere,
          steps: sphere.steps.map(step => this.locations.find(l => l.id === step)),
        };

        newSphere.color = !newSphere.steps.map(s => s.done).includes(false) ? 'green darken-3' : 'primary';

        return newSphere;
      });
    },
    playthroughFiltered() {
      return this.playthrough
          .map(sphere => {
            return {
              ...sphere,
              steps: this.$options.filters.filterBy({
                items: sphere.steps,
                areas: this.areas,
                search: this.search,
              })
            };
          })
          .filter(sphere => !!sphere.steps.length);
    },
    progress() {
      return Math.round(
          this.playthrough.reduce((p, c) => p + c.steps.reduce((sp, sc) => sp + sc.done, 0), 0)
          / this.playthrough.reduce((p, c) => p + c.steps.length, 0)
          * 100
      );
    },
    progressColor() {
      return this.playthrough[this.playthrough.length - 1].color;
    },
    hasTriforce() {
      return this.$store.getters['Log/settings'].find(s => s.name === 'Triforce Hunt')?.value === 'On';
    },
    triforceCurrent() {
      return this.$store.getters['Log/locations'].filter(l => l.item === 'Triforce Piece' && l.done).length;
    },
    triforceTotal() {
      return this.$store.getters['Log/settings'].find(s => s.name === 'Triforce Hunt Required Pieces')?.value;
    },
    triforceProgress() {
      return this.triforceCurrent / this.triforceTotal * 100;
    },
  },
}
</script>

<style scoped>
.progress {
  z-index: 3;
}

.v-timeline {
  padding-top: 0;
}
</style>