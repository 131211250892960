<template>
  <v-card
      width="100%"
      class="nocopy"
  >
    <v-toolbar
        dense
        flat
        class="interactable"
        @click="setDone"
        :class="{ 'green darken-3': value.done }"
    >
      <v-img
          :src="areaIcon"
          width="24"
          max-width="24"
          height="24"
          max-height="24"
          contain
          class="mr-3"
      />

      <b>{{ value.id }}</b>

      <v-spacer/>

      <div v-show="showPrices">
        <spoiled-rupee :amount="value.price" size="24"/>
      </div>

      <div v-if="showTraps">
        <v-icon color="cyan">mdi-alert-outline</v-icon>
      </div>
    </v-toolbar>

    <div class="px-4 py-3 text--secondary" :class="{ 'no-spoilers': !showSpoilers }">
      {{ value.item }}
    </div>
  </v-card>
</template>

<script>
import SpoiledRupee from "@/components/base/SpoiledRupee";
import areas from "@/helper/areas";

export default {
  name: "SpoiledLocation",
  components: {SpoiledRupee},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    areaIcon() {
      return this.value.area.icon ?? areas.find(a => a.name === this.value.area).icon;
    },
    showSpoilers() {
      return this.$store.getters['Settings/showSpoilers']
          || this.$store.getters['Settings/showFound'] && this.value.done;
    },
    showTraps() {
      return (this.$store.getters['Settings/showTraps']
              || this.$store.getters['Settings/showFound'] && this.value.done)
          && this.value.trap;
    },
    showPrices() {
      return this.$store.getters['Settings/showPrices']
          || this.$store.getters['Settings/showFound'] && this.value.done;
    },
  },
  methods: {
    setDone() {
      this.$store.commit('Log/setLocationDone', {
        location: this.value,
        done: !this.value.done,
      });
    }
  },
}
</script>

<style scoped>

</style>