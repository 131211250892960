<template>
  <v-card
      width="100%"
      class="nocopy"
  >
    <v-toolbar
        dense
        flat
        class="interactable"
        @click="setDone"
        :class="{ 'green darken-3': value.done }"
    >
      <v-layout
          d-flex
          align-center
      >
        <v-icon>{{ icon }}</v-icon>

        <b class="ml-1">{{ value.id }}</b>
      </v-layout>
    </v-toolbar>

    <div
        class="px-4 py-3 text--secondary"
        :class="{ 'no-spoilers': !showSpoilers, 'text-center': centered }"
        v-html="value.hint"
    />
  </v-card>
</template>

<script>
export default {
  name: "SpoiledHint",
  props: {
    value: {
      type: Object,
      required: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showSpoilers() {
      return this.$store.getters['Settings/showSpoilers']
          || this.$store.getters['Settings/showFound'] && this.value.done;
    },
    icon() {
      return [
        {type: 'altar', icon: 'mdi-table-furniture'},
        {type: 'special', icon: 'mdi-star-four-points'},
        {type: 'song', icon: 'mdi-music-note'},
        {type: 'gossip', icon: 'mdi-grave-stone'},
      ]
          .find(i => i.type === this.value.type).icon;
    },
  },
  methods: {
    setDone() {
      this.$store.commit('Log/setHintDone', {
        hint: this.value,
        done: !this.value.done,
      });
    }
  }
}
</script>

<style scoped>

</style>