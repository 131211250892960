import base from "@/helper/parser/base";
import areas from "@/helper/areas";

export default {
    ...base,

    parseHints: (data) => {
        let counter = 0;

        return [
            {id: 'ToT Child Altar', hint: data['Static Hints']['ToT Altar as Child'].areas.map(a => '$' + counter++ + a).join('&'), type: 'altar'},
            {id: 'ToT Adult Altar', hint: data['Static Hints']['ToT Altar as Adult'].areas.map(a => '$' + counter++ + a).join('&'), type: 'altar'},

            {id: 'Ganondorf Hint', hint: data['Static Hints']['Ganondorf Hint']?.message, type: 'special'},
            {id: 'Ganondorf Joke', hint: data['Static Hints']['Ganondorf Joke']?.message, type: 'special'},
            {id: 'Sheik in Ganons Castle Hint', hint: data['Static Hints']['Sheik in Ganons Castle Hint']?.message, type: 'special'},
            {id: 'Dampe\'s Diary Hint', hint: data['Static Hints']['Dampe\'s Diary Hint']?.message, type: 'special'},
            {id: 'Treasure Chest Game Greg Hint', hint: data['Static Hints']['Treasure Chest Game Greg Hint']?.message, type: 'special'},
            {id: 'Saria\'s Magic Hint', hint: data['Static Hints']['Saria\'s Magic Hint']?.messages[0], type: 'special'},
            {id: 'Fishing Pole Hint', hint: data['Static Hints']['Fishing Pole Hint']?.message, type: 'special'},

            {id: 'Minuet of Forest Destination', hint: data['Static Hints']['Minuet of Forest Destination']?.area, type: 'song'},
            {id: 'Bolero of Fire Destination', hint: data['Static Hints']['Bolero of Fire Destination']?.area, type: 'song'},
            {id: 'Serenade of Water Destination', hint: data['Static Hints']['Serenade of Water Destination']?.area, type: 'song'},
            {id: 'Requiem of Spirit Destination', hint: data['Static Hints']['Requiem of Spirit Destination']?.area, type: 'song'},
            {id: 'Nocturne of Shadow Destination', hint: data['Static Hints']['Nocturne of Shadow Destination']?.area, type: 'song'},
            {id: 'Prelude of Light Destination', hint: data['Static Hints']['Prelude of Light Destination']?.area, type: 'song'},

            ...Object.entries(data['Gossip Stone Hints']).map(h => {
                return {
                    id: h[0],
                    hint: h[1].message,
                    type: 'gossip',
                }
            })
        ]
            .filter(h => !!h.hint)
            .map(h => {
                h.area = areas.find(a => a.locations.includes(h.id))?.name;
                h.done = false;

                return h;
            })
            .sort((a, b) => a.id.localeCompare(b.id, undefined, {numeric: true, sensitivity: 'base'}));
    },
}