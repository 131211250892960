import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from "vue-meta"
import router from './router'

Vue.config.productionTip = false

Vue.use(VueMeta)

Vue.filter('capitalize', (value) => value.charAt(0).toUpperCase() + value.slice(1))

Vue.filter('filterBy', ({items, areas = [], search = ''}) => {
    return items
        .filter(i =>
            !areas.length
            || areas.some(a => a.name === i.area)
        )
        .filter(
            i => !search
                || i.id.toLowerCase().includes(search.toLowerCase())
                || (
                    (store.getters['Settings/showSpoilers']
                        || store.getters['Settings/showSearch']
                        || (i.done && store.getters['Settings/showFound']))
                    && (i.item?.toLowerCase().includes(search.toLowerCase())
                        || i.hint?.toLowerCase().includes(search.toLowerCase()))
                )
        );
})

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
