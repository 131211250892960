import latest from "@/helper/parser/latest";
import base from "@/helper/parser/base";

export default {
    parser: [
        {parser: base, version: '8.0.6'},
        {parser: latest, version: 'latest'},
    ],

    parse(content) {
        return new Promise((resolve, reject) => {
            try {
                let file = JSON.parse(content) ?? content;

                let id = file['file_hash'].join('-');
                let hash = file['file_hash'];
                let version = /\((.+)\)/.exec(file['version'] ?? file['_version'])[1];

                if (version < '7.0.0') reject({msg: 'Unsupported version'});
                if (!id || !hash || !version) reject({msg: 'Invalid log file'});

                resolve({
                    id, hash, version, file,
                    ...this.parser.find(p => p.version >= version)?.parser.parse(file),
                });
            } catch(e) {
                reject({msg: 'Invalid log file'});
            }
        })
    },
}